// src/features/ProductCatalogue/services/api.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://node.wescanedmoton.com';

// API endpoints configuration
const endpoints = {
  styles: '/api/ss/styles',
  cachedStyles: '/api/ss/cached/styles',
  stylePriority: '/api/ss/cached/style-priority',
  products: '/api/ss/products',
};

// Create axios instance with default config
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add response interceptor for error handling
api.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', error);
    return Promise.reject({
      status: error.response?.status,
      message: error.response?.data?.message || 'An unexpected error occurred'
    });
  }
);

// API methods using the endpoints configuration
export const styleService = {
  async fetchStylePriority() {
    return api.get(endpoints.stylePriority);
  },

  async fetchCachedStyles() {
    return api.get(endpoints.cachedStyles);
  },


  async fetchStyles() {
    return api.get(endpoints.styles);
  },

  async fetchStyleWithProducts(styleId) {
    const [styleResponse, productsResponse] = await Promise.all([
      api.get(`${endpoints.styles}/${styleId}`),
      api.get(`${endpoints.products}?style=${styleId}`)
    ]);

    
    return {
      style: styleResponse.data[0],
      products: productsResponse.data
    };

  }
};

export default styleService;
