// src/features/pages/Home.jsx
import React, { useState, useCallback, useEffect } from 'react';
import { useStyles } from '../hooks/useStyles';
import StyleList from '../components/StyleList';
import Filters from '../components/Filters';
import PaginationComponent from '../components/PaginationComponent';
import { getUniqueCategories, getUniqueBrands, getBrandDisplayName } from '../services/utils';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Offcanvas, Button } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight, FaFilter } from 'react-icons/fa'; // Import icons

const ITEMS_PER_PAGE = 30;

const Home = () => {
  // Use custom hook for styles data
  const { styles, loading, error, lastUpdated } = useStyles();
  
  // Move filter state up to parent component
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [filteredStyles, setFilteredStyles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Memoize categories
  const categories = React.useMemo(() => 
    getUniqueCategories(styles), [styles]);

  // Memoize brands alongside categories
  const brands = React.useMemo(() => 
    getUniqueBrands(styles), [styles]);

  // Handle category changes
  const handleCategoryChange = useCallback((category) => {
    setSelectedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(cat => cat !== category) 
        : [...prev, category]
    );
  }, []);

  // Handle brand changes
  const handleBrandChange = useCallback((brand) => {
    setSelectedBrands(prev => 
      prev.includes(brand) 
        ? prev.filter(b => b !== brand) 
        : [...prev, brand]
    );
  }, []);

  // Handle search changes
  const handleSearchChange = useCallback((value) => {
    setSearchTerm(value);
  }, []);

  // Update filtered styles when filters change
  useEffect(() => {
    let filtered = styles;

    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(style =>
        (style.styleName?.toLowerCase().includes(term)) ||
        (style.brandName?.toLowerCase().includes(term)) ||
        (style.title?.toLowerCase().includes(term)) ||
        (style.description?.toLowerCase().includes(term))
      );
    }

    if (selectedCategories.length > 0) {
      filtered = filtered.filter(style => {
        const cats = Array.isArray(style.baseCategory) 
          ? style.baseCategory 
          : [style.baseCategory];
        return cats.some(cat => selectedCategories.includes(cat));
      });
    }

    if (selectedBrands.length > 0) {
      filtered = filtered.filter(style => 
        selectedBrands.includes(style.brandName)
      );
    }

    setFilteredStyles(filtered);
    setCurrentPage(1);
  }, [styles, searchTerm, selectedCategories, selectedBrands]);

  // Calculate pagination
  const totalPages = Math.ceil(filteredStyles.length / ITEMS_PER_PAGE);
  const currentStyles = filteredStyles.slice(
    (currentPage - 1) * ITEMS_PER_PAGE, 
    currentPage * ITEMS_PER_PAGE
  );

  // Toggle sidebar collapse
  const toggleSidebar = () => {
    setIsSidebarCollapsed(prev => !prev);
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '20px' }}>
      <div className="row">
        {/* Toggle Button for Small Screens */}
        <div className="col-12 d-md-none mb-3">
          <Button variant="primary" onClick={() => setShowFilters(true)}>
            <FaFilter /> Filters
          </Button>
        </div>

        {/* Left Pane Filters for Medium and Larger Screens */}
        <div
          className={
            !isSidebarCollapsed
              ? "col-lg-2 col-md-3 d-none d-md-block bg-light"
              : "d-none d-md-block"
          }
          id="left-pane"
        >
          {!isSidebarCollapsed && (
            <div className="d-flex flex-column h-100">
              <Filters 
                categories={categories}
                brands={brands}
                searchTerm={searchTerm}
                selectedCategories={selectedCategories}
                selectedBrands={selectedBrands}
                onSearchChange={handleSearchChange}
                onCategoryChange={handleCategoryChange}
                onBrandChange={handleBrandChange}
              />
              <Button 
                variant="" 
                onClick={toggleSidebar} 
                className="mt-2 align-self-center"
                aria-label="Collapse Filters"
              >
                <FaChevronLeft /> Hide Filters
              </Button>
            </div>
          )}
        </div>

        {/* Main Content Area */}
        <div
          className={
            !isSidebarCollapsed
              ? "col-lg-10 col-md-9"
              : "col-lg-12 col-md-12"
          }
          id="main-content"
        >
          {/* Show expand button when sidebar is collapsed */}
          {isSidebarCollapsed && (
            <Button 
              variant="secondary" 
              onClick={toggleSidebar} 
              className="mb-3 d-none d-md-block"
              aria-label="Expand Filters"
            >
              <FaChevronRight /> Filters
            </Button>
          )}

          {loading && <LoadingIndicator />}

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {!loading && !error && (
            <>
              <StyleList styles={currentStyles} />
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </>
          )}
        </div>
      </div>

      {/* Offcanvas for Filters on Small Screens */}
      <Offcanvas show={showFilters} onHide={() => setShowFilters(false)} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Filters 
            categories={categories}
            brands={brands}
            searchTerm={searchTerm}
            selectedCategories={selectedCategories}
            selectedBrands={selectedBrands}
            onSearchChange={handleSearchChange}
            onCategoryChange={handleCategoryChange}
            onBrandChange={handleBrandChange}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {lastUpdated && (
        <div className="text-end mt-4">
          <small className="text-muted" style={{ fontSize: '0.75rem', opacity: 0.7 }}>
            Last updated: {lastUpdated}
          </small>
        </div>
      )}
    </div>
  );
};

export default Home;
