// src/features/ProductCatalogue/components/Filters.jsx
import React from 'react';
import { Form, InputGroup, Card, Accordion } from 'react-bootstrap';
import { getBrandInfo } from '../services/utils';
import './Filters.css'; // Import the custom CSS

const Filters = ({ 
  categories, 
  brands,
  searchTerm,
  selectedCategories,
  selectedBrands,
  onSearchChange,
  onCategoryChange,
  onBrandChange
}) => {
  const handleCategoryChange = (category) => {
    onCategoryChange(category);
  };

  return (
    <Card className="filters-card">
      <Card.Body>
        <h5 className="mb-2">Filter Styles</h5>
        <InputGroup className="search-input">
          <Form.Control
            type="text"
            placeholder="Search styles..."
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </InputGroup>

        <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Categories</Accordion.Header>
            <Accordion.Body>
              <div className="filter-options">
                {categories.map(category => (
                  <Form.Check 
                    key={category}
                    type="checkbox"
                    id={`category-${category}`}
                    label={category}
                    checked={selectedCategories.includes(category)}
                    onChange={() => handleCategoryChange(category)}
                    className="filter-checkbox"
                    title={category} // Add tooltip for truncated text
                  />
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Brands</Accordion.Header>
            <Accordion.Body>
              <div className="filter-options">
                {brands.map(brand => {
                  const { displayName, iconUrl, width, height, alt } = getBrandInfo(brand);
                  return (
                    <Form.Check 
                      key={brand}
                      type="checkbox"
                      id={`brand-${brand}`}
                      className="filter-checkbox"
                      checked={selectedBrands.includes(brand)}
                      onChange={() => onBrandChange(brand)}
                      label={
                        <span className="d-flex align-items-center">
                          {displayName}
                          {iconUrl && (
                            <img
                              src={iconUrl}
                              alt={alt}
                              className="ms-2 brand-icon"
                              width={width}
                              height={height}
                            />
                          )}
                        </span>
                      }
                      title={displayName}
                    />
                  );
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default Filters;
