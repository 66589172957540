// src/utils.js

// Add this near the top of the file, after the imports
export const EXCLUDED_BRANDS = [
  'ALSTYLE',
  'Anvil',
  'Calvin Klein',
  ''
  // Add any other brands you want to exclude
];

// Merges ranked styles with detailed styles based on the styleID
export function mergeStyles(rankedStyles, detailedStyles) {
    return rankedStyles.map(rankedStyle => {
      const detailedStyle = detailedStyles.find(style => style.styleID === rankedStyle.styleID);
      return {
        ...rankedStyle,
        ...detailedStyle, // This includes fields like title, description, etc.
      };
    });
  }
  
  // Update the brand mapping to include both display names and icons
  export const brandMapping = {
    'Gildan': {
      displayName: 'Gildan',
      iconUrl: 'https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/ca.svg',
      width: '20px',
      height: '15px',
      alt: 'Canadian Flag'
    },

    'King Fashion': {
      displayName: 'King Fashion',
      iconUrl: 'https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/ca.svg',
      width: '20px',
      height: '15px',
      alt: 'Canadian Flag'
    },
    'Independent Trading Co.': {
      displayName: 'Independent',
      iconUrl: '',
      width: '20px',
      height: '15px',
      alt: 'Canadian Flag'
    },
    'Adidas': {
      displayName: 'Adidas',
      iconUrl: 'https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/eu.svg',
      width: '20px',
      height: '15px',
      alt: 'European Flag'
    },
    'Atlantis Headwear': {
      displayName: 'Atlantis Headwear',
      iconUrl: 'https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/eu.svg',
      width: '20px',
      height: '15px',
      alt: 'European Flag'
    },
  };
  
  // Helper function to get brand info (combines previous separate functions)
  export function getBrandInfo(brandName) {
    return brandMapping[brandName] || { displayName: brandName };
  }
  
  // Extracts unique categories from the styles
  export function getUniqueCategories(styles) {
    const categoriesSet = new Set();
    styles.forEach(style => {
      const styleCategories = Array.isArray(style.baseCategory) ? style.baseCategory : [style.baseCategory];
      styleCategories.forEach(category => {
        if (category) {
          categoriesSet.add(category);
        }
      });
    });
    return Array.from(categoriesSet).sort();
  }
  
  // Update the getUniqueBrands function to filter out excluded brands
  export function getUniqueBrands(styles) {
    return Array.from(
      new Set(
        styles
          .filter(style => style.brandName && !EXCLUDED_BRANDS.includes(style.brandName))
          .map(style => style.brandName)
      )
    ).sort();
  }
  
  // Update the helper function
  export function getBrandIcon(brandName) {
    return brandMapping[brandName] || null;
  }
  