import { useState, useEffect } from 'react';
import { styleService } from '../services/api';
import { mergeStyles, EXCLUDED_BRANDS } from '../services/utils';

export const useStyles = () => {
  const [styles, setStyles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchStyles = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch ranked styles and extract the styles array from the response
        const priorityResponse = await styleService.fetchStylePriority();
        const rankedStyles = priorityResponse.data.styles;
        
        // Format the timestamp
        const timestamp = new Date(priorityResponse.data.lastUpdated);
        const formattedDate = timestamp.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
          timeZoneName: 'short'
        });
        
        setLastUpdated(formattedDate);

        const stylesResponse = await styleService.fetchCachedStyles();
        // Update to access the styles array from the new response format
        const allStyles = mergeStyles(rankedStyles, stylesResponse.data.styles)
          .filter(style => {
            const categories = Array.isArray(style.baseCategory) 
              ? style.baseCategory 
              : [style.baseCategory];
            // Filter out both Office Use category and excluded brands
            return !categories.includes('Office Use') && 
                   !EXCLUDED_BRANDS.includes(style.brandName);
          });

        if (isMounted) {
          setStyles(allStyles);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching styles:', error);
        if (isMounted) {
          setError(error.message);
          setLoading(false);
        }
      }
    };

    fetchStyles();

    return () => {
      isMounted = false;
    };
  }, []);

  return { styles, loading, error, lastUpdated };
}; 