// src/components/StyleCard.jsx
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getBrandInfo } from '../services/utils';

const StyleCard = ({ style }) => {
  const availabilityIcon = getAvailabilityIcon(style.totalQty);
  const updatedStyleImage = style.styleImage;
  const { displayName: brandDisplayName, iconUrl, width, height, alt } = getBrandInfo(style.brandName);

  return (
    <Card 
      className="h-100 shadow-sm" 
      style={{ 
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: '8px',
        overflow: 'hidden'
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'translateY(-5px)';
        e.currentTarget.style.boxShadow = '0 4px 15px rgba(0,0,0,0.1)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'translateY(0)';
        e.currentTarget.style.boxShadow = '0 1px 3px rgba(0,0,0,0.1)';
      }}
    >
      <Link to={`/product/${style.styleID}`} className="text-decoration-none text-dark">
        <div className="position-relative" style={{ paddingTop: '110%' }}>
          <Card.Img 
            variant="top" 
            src={`https://www.ssactivewear.com/${updatedStyleImage}`} 
            alt={style.styleName} 
            loading="lazy"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              backgroundColor: '#f8f9fa',
              padding: '0'
            }}
          />
          <div 
            className="position-absolute top-0 end-0 m-2"
            style={{ 
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor: '#fff',
              boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {availabilityIcon}
          </div>
        </div>
        
        <Card.Body className="d-flex flex-column" style={{ padding: '1rem' }}>
          <Card.Title 
            className="mb-2" 
            style={{ 
              fontSize: '0.9rem',
              fontWeight: '600',
              lineHeight: '1.2'
            }}
          >
            {style.styleName}
          </Card.Title>
          
          <div className="d-flex align-items-center mb-2">
            <span className="fw-bold me-2" style={{ fontSize: '0.85rem' }}>
              {brandDisplayName}
            </span>
            {iconUrl && (
              <img
                src={iconUrl}
                alt={alt}
                width={width}
                height={height}
                style={{ opacity: 0.9 }}
              />
            )}
          </div>
          
          <Card.Text 
            className="text-muted mb-0" 
            style={{ 
              fontSize: '0.8rem',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: '1.3'
            }}
          >
            {style.title}
          </Card.Text>
        </Card.Body>
      </Link>
    </Card>
  );
};

const getAvailabilityIcon = (totalQty) => {
  if (totalQty < 100) {
    return (
      <div 
        title={`Limited Stock: ${totalQty} units available`}
        style={{ 
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#dc3545'
        }}
      >
        ✕
      </div>
    );
  } else if (totalQty >= 100 && totalQty <= 999) {
    return (
      <div 
        title={`${totalQty} units available`}
        style={{ 
          width: '100%',
          height: '100%',
          backgroundColor: '#ffc107',
          borderRadius: '50%',
        }}
      />
    );
  } else {
    return (
      <div 
        title={`Well Stocked: ${totalQty}+ units available`}
        style={{ 
          width: '100%',
          height: '100%',
          backgroundColor: '#28a745',
          borderRadius: '50%',
        }}
      />
    );
  }
};

export default StyleCard;
